<template>
  <div :class="['location karte', 'page-content']">
    <div class="location-top d-flex justify-content-between d-lg-none">
      <div class="btns-group">
        <button @click="showTripList"  :class="['btn btn-sm']">Liste</button>
        <button :class="['btn btn-sm active']">Karte</button>
      </div>

      <button class="btn btn-simple btn-icon" @click="filterDropdown = !filterDropdown">Filter
        <span class="material-icons">{{ filterDropdown ? 'expand_less' : 'expand_more' }}</span>
      </button>
    </div>
    <div v-if="filterDropdown" class="filter-dropdown d-lg-none">
      <filter-options :dropdown="true" />
    </div>

    <div class="sidebar">
      <form action="#!" class="clearfix d-none d-lg-block">

        <destination-select v-if="false" @changedSelectedDestination="changeSelectedDestination" selectClass="form-control input-rounded border icon-right"/>

        <button class="btn btn-simple btn-icon float-right" type="button" @click="filter = !filter">
          Filter <span class="material-icons">{{ !filter ? 'expand_more' : 'expand_less'}}</span>
        </button>
        <div class="clearfix"></div>
        <filter-options v-if="filter" />
      </form>

      <div class="sidebar__scrolling">
        <card-slider :data="reiseplane" card="reiseplan" :desktopAuto="true" :bgTransparent="true" />
      </div>
    </div>
    <multiple-map class="map" ref="theMap" @highlight="highlightThisContent"></multiple-map>

  </div>
</template>

<script>
  export default {
    name: 'ReiseplaneKarte',
    components: {
      CardSlider: () => import('@/components/CardSlider'),
      FilterOptions: () => import('@/components/FilterOptions'),
      MultipleMap: () => import('@/components/map/multiple.vue'),
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
    },
    data: () => ({
      liste: true,
      filter: false,
      filterDropdown: false,
      reiseplane: [
      { image: '/assets/highlights/thumbnails/gastro.jpg', name: 'Surfen in SH', info: '14 Destinationen', link: '/reiseplane/view/surfen-in-sh' },
      { image: '/assets/highlights/thumbnails/kultur.jpg', name: 'Musik und Bars', info: '25 Destinationen', link: '/reiseplane/view/musik-und-bars' },
      { image: '/assets/highlights/thumbnails/shopping.jpg', name: 'Noch ein Reiseplan', info: 'Kultur, Sightseeing', link: '/reiseplane/view/noch-ein-reiseplan' },
      { image: '/assets/highlights/thumbnails/outdoor.jpg', name: 'Und noch ein Reiseplan', info: 'Kultur, Sightseeing', link: '/reiseplane/view/und-noch-ein-reiseplan' },
      { image: '/assets/highlights/thumbnails/wellness.jpg', name: 'Und noch ein Reiseplan', info: 'Kultur, Sightseeing', link: '/reiseplane/view/und-noch-ein-reiseplan' }
      ]
    }),
    methods: {
      changeSelectedDestination(destination){
        this.$emit('changedSelectedDestination',destination);
      },
      selectCategory(category) {
        this.$emit('changedSelectedCategory',category);
      },
      showTripList(){
        this.$router.push({ name: 'Trips' });
      }
    }
  }
</script>
